<template>
  <button :class="classNames">
    <slot></slot>
  </button>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "normal",
    },
  },
  computed: {
    classNames: function() {
      const classNames = ["btn"];
      classNames.push(`btn-${this.type}`);
      classNames.push(`btn-${this.size}`);
      return classNames.join(" ");
    },
  },
};
</script>
