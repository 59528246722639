import {
    createRouter,
    createWebHashHistory,
    createWebHistory,
    RouteRecordRaw,
} from "vue-router";
import Home from "../views/Home.vue";

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import ( /* webpackChunkName: "login" */ "../views/Login.vue"),
    },
    {
        path: "/nottimes",
        name: "Nottimes",
        component: () =>
            import ( /* webpackChunkName: "nottimes" */ "../views/Nottimes.vue"),
    },
    {
        path: "/face",
        name: "Face",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "face" */ "../views/Face.vue"),
    },
    {
        path: "/tongue",
        name: "Tongue",
        component: () =>
            import ( /* webpackChunkName: "tongue" */ "../views/Tongue.vue"),
    },
    {
        path: "/transition",
        name: "Transition",
        component: () =>
            import ( /* webpackChunkName: "tongue" */ "../views/Transition.vue"),
    },
    {
        path: "/question",
        name: "Question",
        component: () =>
            import ( /* webpackChunkName: "question" */ "../views/Question.vue"),
    },
    {
        path: "/report",
        name: "Report",
        component: () =>
            import ( /* webpackChunkName: "report" */ "../views/Report.vue"),
    },
    {
        path: "/list",
        name: "List",
        component: () =>
            import ( /* webpackChunkName: "list" */ "../views/List.vue"),
    },
    {
        path: "/reportList",
        name: "reportList",
        component: () =>
            import ( /* webpackChunkName: "list" */ "../views/reportList.vue"),
    },
    {
        path: "/device",
        name: "Device",
        component: () =>
            import ( /* webpackChunkName: "list" */ "../views/Device.vue"),
    },
    {
        path: "/error",
        name: "Error",
        component: () =>
            import ( /* webpackChunkName: "error" */ "../views/Error.vue"),
    },
  
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;