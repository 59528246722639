<template>
  <router-view />
</template>
<script>
export default {
  mounted() {
    const resize = function() {
      const clientWidth = window.innerWidth;
      const clientHeight = window.innerHeight;
      let fontSize;
      if(clientWidth>clientHeight){
        fontSize = (12 * clientHeight) / 475;
      }else{
        fontSize = (12 * clientWidth) / 375;
      }
      document.documentElement.style.fontSize = `${fontSize}px`;
    };
    //window.onresize = resize;
    resize();
  },

  unmounted() {
    window.onresize = null;
  },
};
</script>
