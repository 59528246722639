<template>
  <div class="gray-bg3">
     <!--<div class="fixed-box">
      <van-nav-bar
        title="评估"
        left-text=""
        @click-left="onClickLeft"
        fixed
      />
    </div> -->
    <div class="test-bg">
      <div class="test-title">
        <img src="/assets/new_report/assess-title.png" alt="" />
      </div>
      <div
        class="flex-row align-items test-advantage"
        v-for="(item, index) in advantList"
        :key="index"
      >
        <img :src="item.url" alt="" />
        <p>
          <span class="advant-title">{{ item.title }}</span>
          <br />
          <span class="advant-txt">{{ item.desc }}</span>
        </p>
      </div>
    </div>
    <div class="newtest-step">
      <p class="step-title">健康评估流程</p>
      <div class="flex-row test-box">
        <div
          class="test-box-item"
          v-for="(item, index) in stepList"
          :key="index"
        >
          <img :src="item.url" alt="" />
          <p class="step-txt">
            <span class="step-num">{{ index + 1 }}</span>
            <span class="step-font">{{ item.title }}</span>
            <br />
            <span class="step-desc">({{ item.desc }})</span>
          </p>
        </div>
      </div>
      <p class="start-title">
        点击“开始评估”，即表示您同意将照片上传至AI中医，用于健康分析
      </p>
      <div class="test-btn" @click="onStart">开始评估</div>
    </div>
  </div>
  <div class="hu-assess">
      <div class="assess">
          <img src="/assets/new_report/assess-title.png" alt="" />
          <div
            class="flex-row align-items assess-list"
            v-for="(item, index) in advantList"
            :key="index"
          >
            <img :src="item.url" alt="" />
            <div class="assess-title">{{ item.title }}</div>
            <div class="assess-flex1">{{ item.desc }}</div>
          </div>
      </div>
      <div @click="onStartBool" class="assess-button">开始评估</div>
      <div @click="onStartList" class="assess-lists">报告列表</div>
  </div>
  <div class="assess-ment">
    <span>点击“开始评估”，即表示您同意将照片上传至AI中医，用于健康分析</span>
    <!-- <span class="assess-id">激活序列号：</span>
    <span class="device">{{device}}</span> -->
  </div>
</template>
<script>
import Button from "../components/common/button";
import { LOGIN_URL } from "../meta/variables";
import { checkAgency,getUserByCode,getMacCode } from "../services/api";
import "./new_test.scss";
import { Toast } from "vant";
export default {
  name: "Home",
  data() {
    return {
      CompanyName: "",
      device:"",
      advantList: [
        {
          url: require("../../public/assets/new_report/major.png"),
          title: "专业",
          desc:
            "国家科技部和中医药管理局重大研发项目“中医四诊现代化”系列成果转化",
        },
        {
          url: require("../../public/assets/new_report/intelligence.png"),
          title: "智能",
          desc: "浙江大学人工智能研究所合作开发",
        },
        {
          url: require("../../public/assets/new_report/security.png"),
          title: "安全",
          desc: "阿里云部署",
        },
      ],
      stepList: [
        {
          url: require("../../public/assets/new_report/faces.png"),
          title: "面诊",
          desc: "拍面象",
        },
        {
          url: require("../../public/assets/new_report/tongue.png"),
          title: "舌诊",
          desc: "拍舌诊",
        },
        {
          url: require("../../public/assets/new_report/interrogation.png"),
          title: "问诊",
          desc: "回答问题",
        },
      ],
      user_id:''
    };
  },
  methods: {
    onStart: function () {
      this.$router.push({
        path: "/face",
        // order_code, user_id, user_phone,
        // query: { ...this.$route.query },
        query: { order_code: this.$route.query.bud_e||this.$route.query.order_code, user_id: this.user_id || this.$route.query.user_id||sessionStorage.getItem('user_id') },
      });
    },
    onStartList:function(){
      this.$router.push({
        path: "/list",
        query: { order_code: this.$route.query.bud_e||this.$route.query.order_code, user_id: this.user_id || this.$route.query.user_id||sessionStorage.getItem('user_id') },
      });
    },
    onStartBool:function(){
      // if(this.device){
          this.onStart();
      // }else{
      //     Toast.fail("mac码不能为空！");
      // }
    },
    //返回上一级
    onClickLeft:function(){
      this.$router.go(-1)
    }
  },
  mounted() {
    // if (this.$route.query.mac) {
    //   localStorage.setItem('mac',this.$route.query.mac);
    // }
    if (this.$route.query.code) {
      checkAgency().then((res) => {
        if (res.code === 0) {
          if (
            res.data.limit_count < res.data.now_report_count ||
            res.data.limit_count == res.data.now_report_count
          ) {
            this.$router.push({ path: "/nottimes" });
          }
        }
      });

      let user_id = this.user_id || sessionStorage.getItem('user_id');
      if(!user_id){
          getUserByCode(this.$route.query.code).then((res) => {
            if (res.code === 0) {
              this.user_id = res.data;
              sessionStorage.setItem('user_id',res.data);

              //window.location.href = `/?order_code=${this.$route.query.bud_e}&user_id=${res.data}`;

              // let mac = localStorage.getItem('mac');
              // if(this.$route.query.mac||mac){
              //   getMacCode({
              //     mac:this.$route.query.mac||mac,
              //     user_id:res.data
              //   }).then((data)=>{
              //     if(!data.code){
              //       if(data.data.status){
              //         localStorage.removeItem("mac");
              //         this.device = data.data.code;
              //       }else{
              //         this.$router.push({
              //           path: "/device",
              //           query: { 
              //             mac: this.$route.query.mac||mac, 
              //             user_id: this.$route.query.user_id,
              //             order_code: this.$route.query.order_code
              //           },
              //         });
              //       }
              //     }else{
              //       if(data.code==100){
              //         window.location.href = `${LOGIN_URL}?order_code=${this.$route.query.bud_e}`;
              //       }else{
              //         this.$router.push({
              //           path: "/device",
              //           query: { 
              //             mac: this.$route.query.mac||mac, 
              //             user_id: this.$route.query.user_id,
              //             order_code: this.$route.query.order_code
              //           },
              //         });
              //       }
              //     }
              //  })
            }
            // this.$router.push({
            //   path: "/",
            //   query: { order_code: this.$route.query.bud_e, user_id: res.data },
           });
          //}
      //});
      // }else{
      //   let mac = localStorage.getItem('mac');
      //   if(this.$route.query.mac||mac){
      //     getMacCode({
      //       mac:this.$route.query.mac||mac,
      //       user_id:this.$route.query.user_id||sessionStorage.getItem('user_id')
      //     }).then((data)=>{
      //       if(!data.code){
      //         if(data.data.status){
      //           localStorage.removeItem("mac");
      //           this.device = data.data.code;
      //         }else{
      //           this.$router.push({
      //             path: "/device",
      //             query: { 
      //               mac: this.$route.query.mac||mac, 
      //               user_id: this.$route.query.user_id,
      //               order_code: this.$route.query.order_code
      //             },
      //           });
      //         }
      //       }else{
      //         if(data.code==100){
      //           window.location.href = `${LOGIN_URL}?order_code=${this.$route.query.bud_e}`;
      //         }else{
      //           this.$router.push({
      //             path: "/device",
      //             query: { 
      //               mac: this.$route.query.mac||mac, 
      //               user_id: this.$route.query.user_id,
      //               order_code: this.$route.query.order_code
      //             },
      //           });
      //         }
      //       }
      //     })
      //  }
      }

      return;
    }

    if (!(this.$route.query.user_id || this.$route.query.code)) {
      window.location.href = `${LOGIN_URL}?order_code=${this.$route.query.bud_e}`;
    }
    
  },
};
</script>
